import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./Newsroom.css";
import { useParams } from "react-router-dom";
import axios from "axios";

const ViewNewsroom = () => {
  const [newsroomData, setNewsroomData] = useState(null);
  const { newsroomid } = useParams();

  useEffect(() => {
    getCareerData();
  }, [newsroomid]);

  const getCareerData = async () => {
    try {
      const response = await axios.get(`https://node.technotackle.in/NewsRoom/${newsroomid}`);
      setNewsroomData(response.data.data);
    } catch (error) {
      console.error("Error fetching newsroom data:", error);
    }
  };

  return (
    <>
      <Header />

      <section>
        <div className="container w-75 mx-auto">
          <div className="py-5">
            {newsroomData ? (
              <>
                <h1>{newsroomData.title}</h1>
                <hr className="pb-0 mb-0" />
                <div className="d-flex py-3">{newsroomData.location} - {newsroomData.publishDate}</div>
                <img src={newsroomData.imageUrl} alt={newsroomData.title} className="w-100" />
                <div className="py-4" style={{ fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: newsroomData.description }} />
              </>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ViewNewsroom;
