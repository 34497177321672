import React from "react";

import { config } from '../config'

export const api = (endPointUrl, type, data) => {
    let baseUrl = config.API_ENDPOINT;
    let salt = 'BeEBloG@!><!2023'
    var md5 = require('md5');

    if (type == 'GET') {
        let get = {
            method: 'GET',
            url: baseUrl + endPointUrl,
            withCredentials: false,
            params: {},
            headers: {
                'auth-token': '0419bab84bmsh83999b5426d0418p1a1d69jsn41961c93f4f7',
            }
        }

        return get;

    } else if (type == "POST") {
        let post = {
            method: 'POST',
            url: baseUrl + endPointUrl,
            data: data,
            headers: {
                'auth-token': md5(salt),
            }
        }

        return post;
    }
}

