import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import '../About/About.css';
import './Service.css';
import Background from '../../assests/images/breadcrumb.png';
import problem from '../../assests/images/problem.png';
import bee from '../../assests/images/bee.png';
import points from '../../assests/images/points.png';
import bike from "../../assests/images/Bike_Financing.webp";
import Fuel from "../../assests/images/Fuel_Loan.webp";
import mobile from '../../assests/images/Smartphone_Loan.webp';
import car from '../../assests/images/car.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Loan from "../../Components/Loan/Loan";
import axios from "axios";

const Service = () => {

  const [pop, setPop] = useState('');
  const [id, setId] = useState(null);
  const [loanModalOpen, setLoanModalOpen] = useState(false);
  const [key, setKey] = useState(0);
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    getFinServiceList();
  }, []);

  const getFinServiceList = async () => {
    try {
      const response = await axios.get('https://node.technotackle.in/service-type/list');
      setServiceData(response.data.data); // Ensure response.data.data is the correct path
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const submitForm = (value1, value2) => {
    setPop(value1);
    setId(value2);
    setLoanModalOpen(true);
    setKey(prevKey => prevKey + 1); // Updated to use functional state update
  };

  console.log(serviceData);

  return (
    <>
      <Header />
      <section className="p-0 m-0">
          <div
            className="pq-breadcrumb"
            style={{ background: `url(${Background})` }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <nav aria-label="breadcrumb">
                    <div className="pq-breadcrumb-title">
                      <h1>Our Services</h1>
                      {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        .
                      </p> */}
                    </div>
                    {/* <div className="pq-breadcrumb-container">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item">
                                                        <a href="index.html">
                                                            <i className="fas fa-home me-2"></i>Home
                                                        </a>
                                                    </li> 
                                                    <li className="breadcrumb-item active">About Us</li>			               			
                                                </ol>        
                                        </div> */}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-0">
          <div className="container mt-5">
            <div className="row  align-items-center">
              <div className="col-xs-12 col-md-12">
                <div className="about-title text-center mb-5">
                  <h1 className="text-uppercase">Financing</h1>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="service-content">
                  <h3 className="mb-4">Problem</h3>
                  <p>
                    Taxi driver don’t have access to traditional financial
                    service cause of lack of collateral and financial story. The
                    only alternative for them to get asset is to rent vehicle
                    indefinitely to a landlord or borrow money in informal
                    market with very high interest rate.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="image-part text-center">
                  <img className="" src={problem} alt="problem" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-0">
          <div className="container mt-5">
            <div className="row  align-items-center">
              <div className="col-xs-12 col-md-6">
                <div className="image-part text-center mb-4">
                  <img className="" src={bee} alt="problem" />
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="service-content">
                  <h3 className="mb-4">Solution</h3>
                  <p>
                    We use data to unlock them loan for asset with the repayment
                    term up to 12 months. and we use IoT to recover the bike by
                    default payment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-0">
          <div className="container mt-5">
            <div className="row  align-items-center">
              <div className="col-xs-12 col-md-6">
                <div className="service-content">
                  <h3 className="mb-4">Advantage</h3>
                  <div className="menu-use-full-link-container">
                    <ul className="menu">
                      <li className="menu-item mb-3">
                        <img className="me-1" src={points} alt="points" /> No
                        Upfront payment
                      </li>
                      <li className="menu-item mb-3">
                        <img className="me-1" src={points} alt="points" />{" "}
                        Digital Daily payment
                      </li>
                      <li className="menu-item mb-3">
                        <img className="me-1" src={points} alt="points" /> No
                        Collateral
                      </li>
                      <li className="menu-item mb-3">
                        <img className="me-1" src={points} alt="points" /> Get
                        Nano & Micro Loan
                      </li>
                      <li className="menu-item mb-3">
                        <img className="me-1" src={points} alt="points" />{" "}
                        Training
                      </li>
                      <li className="menu-item mb-3">
                        <img className="me-1" src={points} alt="points" />{" "}
                        Discount on maintenance service
                      </li>
                      <li className="menu-item mb-3 w-100">
                        <img className="me-1" src={points} alt="points" /> Earn
                        extra income on delivery or transport service
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="image-part text-center">
                  <img className="" src={problem} alt="problem" />
                </div>
              </div>
            </div>
          </div>
        </section>
      <section>
        <div className="container">
          <div className="row">
            {
              serviceData.map((item, index) => (
                item.Service.service_name === "Financing Service" && (
                  <div key={index} className="col-xs-12 col-md-6 col-lg-4 mb-4">
                    <div className="service-card p-4 h-100">
                      <h4 className="mb-4">{item.service_type_name}</h4>
                      <div className="text-center mb-3">
                        <img className="service_card_img" src={item.imageUrl} alt='service' />
                      </div>
                      <p>{item.description}</p>
                      <div className="mt-4">
                        <button
                          onClick={() => submitForm(item.service_type_name, item.id)}
                          className="d-table py-3 px-4"
                          id="bottone1"
                        >
                          <strong>
                            Apply Now <FontAwesomeIcon icon={faAngleRight} />
                          </strong>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              ))
            }
          </div>
        </div>
      </section>
      <Loan key={key} openProps={loanModalOpen} pop={pop} id={id} />
      <Footer />
    </>
  );
}

export default Service;
