import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import '../About/About.css'
import './Service.css'
import Background from '../../assests/images/breadcrumb.png'
import problem from '../../assests/images/problem.png'
import bee from '../../assests/images/bee.png'
import points from '../../assests/images/points.png'
 
import car from '../../assests/images/car.png'
 

const FleetService = () => {

    const [message, setMessage] = useState("");
    const [opne, setopne] = useState(false);

    const submitForm = () => {
        setMessage('Bike Financing');
        setopne(true);
    }


  

    return (
        <>
            <Header />
            <section className="p-0 m-0">
                <div className="pq-breadcrumb" style={{ background: `url(${Background})`, }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <nav aria-label="breadcrumb">
                                    <div className="pq-breadcrumb-title">
                                        <h1>Our Services</h1>
                                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. .</p> */}
                                    </div>
                                    {/* <div className="pq-breadcrumb-container">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item">
                                                        <a href="index.html">
                                                            <i className="fas fa-home me-2"></i>Home
                                                        </a>
                                                    </li> 
                                                    <li className="breadcrumb-item active">About Us</li>			               			
                                                </ol>
                                        </div> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-0">
                <div className="container mt-5">
                    <div className="row  align-items-center">
                        <div className="col-xs-12 col-md-12">
                            <div className="about-title text-center mb-5">
                                <h1 className="text-uppercase">Fleet Management</h1>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="service-content">
                                <h3 className="mb-4">Problem</h3>
                                <p> People want to invest in mobility but they don’t have time to manage their fleet. </p>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="image-part text-center">
                                <img className="" src={problem} alt="problem" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-0">
                <div className="container mt-5">
                    <div className="row  align-items-center">
                        <div className="col-xs-12 col-md-6">
                            <div className="image-part text-center mb-4">
                                <img className="" src={bee} alt="problem" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="service-content">
                                <h3 className="mb-4">Solution</h3>
                                <p>we develop a platform where private people can invest 20% of vehicle cost and they bank finance 80%, Bee will manage the rest, so that they can get passive income.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-0">
                <div className="container mt-5">
                    <div className="row  align-items-center">
                        <div className="col-xs-12 col-md-6">
                            <div className="service-content">
                                <h3 className="mb-4">Advantage</h3>
                                <div className="menu-use-full-link-container">
                                    <ul className="menu">
                                        <li className="menu-item mb-3"><img className="me-1" src={points} alt="points" />  Find a vehicle</li>
                                        <li className="menu-item mb-3"><img className="me-1" src={points} alt="points" /> Hire a driver</li>
                                        <li className="menu-item mb-3"><img className="me-1" src={points} alt="points" /> GPS to track the vehicle</li>
                                        <li className="menu-item mb-3"><img className="me-1" src={points} alt="points" /> Collect the Revenue</li>
                                        <li className="menu-item mb-3"><img className="me-1" src={points} alt="points" /> Provide Loan for Fuel, Maintenance</li>
                                        <li className="menu-item mb-3"><img className="me-1" src={points} alt="points" /> Documentation(Insurance - Transport License - Tax)</li>
                                        <li className="menu-item mb-3 w-100"><img className="me-1" src={points} alt="points" />Provide extra ride with our B2B Partner Yango</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="image-part text-center">
                                <img className="" src={problem} alt="problem" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="container">
                    <div className="row">

                        <div className="col-12">
                            <div className="service-card p-lg-5 p-xs-5 p-3 h-100">
                                <h4 className="mb-4">Invest in car</h4>
                                <div className="d-flex align-items-center gap-3 flex-lg-row justify-content-lg-start justify-content-center flex-column">
                                    <div className="text-center mb-3">
                                        <img src={car} alt="bike" />
                                    </div>
                                    <p>After piloting Motorcycle financing, our vision is to finance other asset and also offer Micro- and Nano loan in less than 24 hours by using our In-house Alternative Credit Scoring System</p>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

            <Footer />


        </>
    )
}

export default FleetService;