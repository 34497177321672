import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../About/About.css";
import "./Career.css";
import jobIcon from "../../assests/images/job.png";
import findIcon from "../../assests/images/find.png";
import uploadIcon from "../../assests/images/upload.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { api } from "../../Api/Api";

const CareerView = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [loader, serLoader] = useState(false);

  const { careerid } = useParams();

  const [careerData, setCareerData] = useState(null);
  const careerId = {
    careerId: careerid,
  };
  useEffect(() => {
    getCareerData();
  }, []);
  const getCareerData = async () => {
    axios
      .request(api(`career/getCareerOne`, "POST", careerId))
      .then(function (response) {
        setCareerData(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
const validUrl = require("valid-url");
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .nullable("")
      .matches(/^[a-zA-Z]+$/, "Name field can not be empty")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    linkedInlink: Yup.string().test(
      "isValidUrl",
      "Invalid LinkedIn URL",
      function (value) {
        if (!value || validUrl.isUri(value)) {
          return true;
        }
        return false;
      }
    ),
    portfoliolink: Yup.string().test(
      "isValidUrl",
      "Invalid portfolio URL",
      function (value) {
        if (!value || validUrl.isUri(value)) {
          return true;
        }
        return false;
      }
    ),

    location: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    message: Yup.string()
      .trim()
      .nullable("")
      .matches(/^[a-zA-Z]+$/, "Name field can not be empty")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    file: Yup.mixed()
      .required("File is required")
      .test("fileSize", "File size too large", (value) =>
        value ? value.size <= 2 * 1024 * 1024 : true
      )
      .test("fileType", "Unsupported file type", (value) =>
        value
          ? [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ].includes(value.type)
          : true
      ),
  });

  const FileUpload = ({ field, form: { setFieldValue }, error, touched }) => {
    const handleFileChange = (event) => {
      const file = event.currentTarget.files[0];

      if (file) {
        setFieldValue(field.name, file);
      }
    };

    return (
      <div className="col-12 col-sm-12 mb-3">
        <div className="file_upload form-control position-relative">
          <span className="file_upload-placeholder">
            <img src={uploadIcon} alt="" /> Upload Resume
          </span>

          <input
            name={field.name}
            type="file"
            id="file"
            className="file_upload-inner"
            placeholder=""
            onChange={handleFileChange}
          />
        </div>
        {error && touched ? <div className="form_error">{error}</div> : null}
        {field.value ? (
          <div className="text-start d-block mt-2">{field.value.name}</div>
        ) : null}
        <span className="text-end d-block">
          Max file size 2MB. Allowed types: pdf, doc.
        </span>
      </div>
    );
  };

  return (
    <>
      <Header />

      {/* banner start*/}
      <section className="py-5 position-relative">
        <div className="carrer_header-icon1">
          {" "}
          <img src={jobIcon} alt="" />{" "}
        </div>
        <div className="carrer_header-icon2">
          {" "}
          <img src={findIcon} alt="" />
        </div>

        <div className="container">
          <div className="career_back">
            <Link
              to="/bee-website/Career"
              className="d-inline-flex align-items-center"
            >
              <span className="me-2 d-inline-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faAngleLeft} />
              </span>{" "}
              Back
            </Link>
          </div>

          <div className="row">
            {careerData &&
              careerData.data &&
              careerData.data.map((item, index) => (
                <div
                  className="col-12 col-sm-8 m-auto carrer_header text-center"
                  key={index}
                >
                  <h3 className="mb-4 fs-40">{item.jobName}</h3>
                  <p className="mb-4">{item.shortDescription}</p>
                  <a href="#applyJob" className="apply_btn">
                    Apply job
                  </a>
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* banner end*/}
      {careerData &&
        careerData.data &&
        careerData.data.map((item, index) => (
          <section className="career_category px-4 py-4 mx-3">
            <div className="career_category-list d-flex justify-content-center align-items-center">
              <div className="career_category-col">
                <div className="career_category-sm">Place</div>
                <div className="career_category-md">{item.place}</div>
              </div>
              <div className="career_category-col">
                <div className="career_category-sm">Category</div>
                <div className="career_category-md">{item.category}</div>
              </div>
              <div className="career_category-col">
                <div className="career_category-sm">Job Type</div>
                <div className="career_category-md">{item.jobType}</div>
              </div>
              <div className="career_category-col">
                <div className="career_category-sm">Job Level</div>
                <div className="career_category-md">{item.jobLevel}</div>
              </div>
            </div>
          </section>
        ))}

      {careerData &&
        careerData.data &&
        careerData.data.map((item, index) => (
          <section className="career_category border-t-0 pb-5">
            <div className="container">
              <div className="row carrer_header">
                <div className="col-12 col-sm-4">
                  <h3 className="fs-40">Job description</h3>
                </div>
                <div className="col-12 col-sm-8">{item.description}</div>
              </div>
            </div>
          </section>
        ))}

      {careerData &&
        careerData.data &&
        careerData.data.map((item, index) => (
          <section className="career_category border-t-0 pb-5">
            <div className="container">
              <div className="row carrer_header">
                <div className="col-12 col-sm-4">
                  <h3 className="fs-40">Job Responsibilities</h3>
                </div>
                <div className="col-12 col-sm-8">
                  <p>{item.responsibilities}</p>

                  {/* <ul className="list_disc pl-3 m-0">
                    <li>
                      Engage with developer communities and project teams to
                      generate leads and new business opportunities
                    </li>
                    <li>
                      Collaborate across teams to achieve business objectives
                    </li>
                    <li>
                      Manage the entire sales lifecycle from prospecting, to
                      deal negotiation, closing, including renewals and upsales.
                    </li>
                    <li>
                      Lead growth marketing across various channels in order to
                      drive market share growth
                    </li>
                    <li>
                      Develop and execute campaigns across multiple channels and
                      platforms with an emphasis on data-driven decision making
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </section>
        ))}
      {careerData &&
        careerData.data &&
        careerData.data.map((item, index) => (
          <section className="career_category border-t-0 pb-5">
            <div className="container">
              <div className="row carrer_header">
                <div className="col-12 col-sm-4">
                  <h3 className="fs-40">Job requirements</h3>
                </div>
                <div className="col-12 col-sm-8">
                  <p>{item.requirements}</p>
                  {/* <ul className="list_disc pl-3 m-0">
                    <li>
                      Strong desire to work in sales at an early-stage startup
                    </li>
                    <li>
                      Knowledge and passion for the crypto/blockchain industry
                    </li>
                    <li>
                      3 years experience with inbound, outbound, and
                      client-facing sales positioning SaaS, 2B product offerings
                    </li>
                    <li>Skilled in prospecting techniques</li>
                    <li>
                      A technical or educational background in engineering,
                      computer science, or MIS
                    </li>
                    <li>
                      Ability to operate independently and proactively in an
                      effort to source and progress new business
                    </li>
                    <li>
                      Excellent listening, verbal and written communication
                      skills
                    </li>
                    <li>
                      Capable of understanding of customer pain points,
                      requirements and correlating potential business to value
                      that can be provided by technical services
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </section>
        ))}

      {careerData &&
        careerData.data &&
        careerData.data.map((item, index) => (
          <section className="career_category border-t-0 pb-5">
            <div className="container">
              <div className="row carrer_header">
                <div className="col-12 col-sm-4">
                  <h3 className="fs-40">Working at Bee</h3>
                </div>
                <div className="col-12 col-sm-8">
                  <p>{item.workingAtBee}</p>
                  <ul className="list_disc pl-3 m-0">
                    <li>
                      Fast moving, challenging and unique business problems
                    </li>
                    <li>
                      International work environment and flat organisation
                    </li>
                    <li>
                      Great career development opportunities in a growing
                      company
                    </li>
                    <li>
                      Possibility for relocation and international transfers
                      mid-career
                    </li>
                    <li>Flexible working hours, Casual work attire</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        ))}

      {/* form  */}
      <section className="" id="applyJob">
        <div className="container">
          <Formik
            initialValues={{
              name: "",
              email: "",
              linkedInlink: "",
              portfoliolink: "",
              location: "",
              message: "",
              file: null,
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { resetForm }) => {
              // same shape as initial values
              console.log(values);
              serLoader(true);

              axios
                .request(
                  api("applyPosition/createApplyPosition", "POST", values)
                )
                .then(function (response) {
                  setEmailSent(true);
                  resetForm();
                  serLoader(false);
                  setTimeout(() => {
                    setEmailSent(false);
                  }, 5000);
                })
                .catch(function (error) {
                  console.error(error);
                });
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-12 col-sm-6 m-auto">
                    <div className="career_form ">
                      <div className="carrer_header text-center">
                        <h3 className="mb-4 fs-40">Apply for this position</h3>
                        <p>
                          If you're on a mission to create impact, we'd love to
                          talk to you and tell you more about the Bee family.
                        </p>
                      </div>

                      <div className="row">
                        <div className="col-12 col-sm-6 mb-3">
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Name"
                          />
                          {errors.name && touched.name ? (
                            <div className="form_error">{errors.name}</div>
                          ) : null}
                        </div>
                        <div className="col-12 col-sm-6 mb-3">
                          <Field
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Email"
                          />
                          {errors.email && touched.email ? (
                            <div className="form_error">{errors.email}</div>
                          ) : null}
                        </div>
                        <div className="col-12 col-sm-6 mb-3">
                          <Field
                            name="linkedInlink"
                            type="text"
                            className="form-control"
                            placeholder="LinkedIn Link"
                          />
                          {errors.linkedInlink && touched.linkedInlink ? (
                            <div className="form_error">
                              {errors.linkedInlink}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-sm-6 mb-3">
                          <Field
                            name="portfoliolink"
                            type="text"
                            className="form-control"
                            placeholder="Portfolio Link"
                          />
                          {errors.email && touched.portfoliolink ? (
                            <div className="form_error">
                              {errors.portfoliolink}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-sm-12 mb-3">
                          <Field
                            name="location"
                            type="text"
                            className="form-control"
                            placeholder="Your Location"
                          />
                          {errors.location && touched.location ? (
                            <div className="form_error">{errors.location}</div>
                          ) : null}
                        </div>
                        <div className="col-12 col-sm-12 mb-3">
                          <Field
                            component="textarea"
                            rows="4"
                            name="message"
                            placeholder="Message"
                            className="form-control"
                          />
                          {errors.message && touched.message ? (
                            <div className="form_error">{errors.message}</div>
                          ) : null}
                        </div>
                        <div className="col-12 col-sm-12 mb-3">
                          <div className="file_upload_one  position-relative">
                            <span className="file_upload-placeholder_one">
                              <img src={uploadIcon} alt="" /> Upload Resume
                            </span>

                            <Field
                              name="file"
                              component={FileUpload}
                              error={errors.file}
                              touched={touched.file}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-sm-12 text-center">
                          {!loader && (
                            <button type="submit" className="btn apply_btn">
                              Submit
                            </button>
                          )}
                          {loader && (
                            <button
                              className="contact-submit-btn mt-4 d-flex align-items-center"
                              disabled
                            >
                              Loading<span class="btn-ring"></span>
                            </button>
                          )}
                          {emailSent && (
                            <div class="alert alert-success mt-2" role="alert">
                              Mail Sent Successfully. We will contact you
                              shortly.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Field name="firstName" />
                                {errors.firstName && touched.firstName ? (
                                    <div>{errors.firstName}</div>
                                ) : null}
                                <Field name="lastName" />
                                {errors.lastName && touched.lastName ? (
                                    <div>{errors.lastName}</div>
                                ) : null}
                                <Field name="email" type="email" />
                                {errors.email && touched.email ? <div>{errors.email}</div> : null}
                                <button type="submit">Submit</button> */}
              </Form>
            )}
          </Formik>
        </div>
      </section>

      {/* form  */}

      <Footer />
    </>
  );
};

export default CareerView;
