import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import '../About/About.css'
import './Tech.css'
import Techtwo from '../../assests/images/tech-image_two.png'
import Techthree from '../../assests/images/tech-image-three.png'
import Techfive from '../../assests/images/tech-image-five.png'
import Techfour from '../../assests/images/tech-image-four.png'




const Tech = () => {
    return (
        <>
            <Header />

            {/* Banner section start */}

            <section className="tech-banner">
                <div className="container">
                    <div className="pt-4 pt-300">
                        <h1 className="tech-title pt-5">Tech Infrastructure </h1>
                        <div className="row justify-content-center pt-3">
                            <div className="col-lg-8 col-md-10 ">
                                <p className="tech-banner_para">BEE is developing a modern technology platform tailored for Africa. It is 100% in house and scalable. This future-proof technology is based on 4 pillars</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* Banner section end */}


            {/* second section start */}

            <section>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6" >
                            <h3 className="tech-titile-two">Desktop Admin Application</h3>
                            <p className="tech-para-two pt-3">Over the years Bee has developed In house solution to securely monitor and manage all the activities in the ecosystem .We digitalize the entire process right from applying , verification ,onboarding of drivers, attaching a leasing plan,payment process and hand off the asset.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="position-relative second-section">
                                <img className="w-100" src={Techtwo}  alt="bannerimage"/>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* second section end */}


            {/* Third section start */}

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex align-items-center section-three">
                            <div className="w-75">
                                <img className="w-100" src={Techthree} alt="Image" />
                            </div>
                            <div className="w-50">
                                <div className="bg-white p-2 p-lg-5 p-md-4   position-relative tech-third-section">
                                    <h3 className="tech-titile-two">Driver Application</h3>
                                    <p className="tech-para-two pt-3">Mobile Application for commercial drivers to easily access for Vehicle subscription, Payments,Maintenance, Insurance And Logistic Service. Our key achievement was when we started our journey it was 100 percent cash collection and today more than 90 percent of our daily payment collection is through mobile money payments</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* Third section end */}


            {/* fourth section start */}

            <section className="forth-section">
                <div className="d-flex align-items-center forth-section-content">
                    <div className="w-50 px-lg-5 px-3 py-lg-0 py-md-0 py-4">
                        <h3 className="tech-title-three">Telematic Solution</h3>
                        <p className="tech-para-three pt-3">With our Partnership with LocoNav(IOT), we are able to recover the assets remotely by default payment without human action. We are also able to understand more about the driver's behavior.
                        </p>
                    </div>
                    <div className="w-75">
                        <img className="w-100" src={Techfour} alt="Image" />
                    </div>
                </div>
            </section>

            {/* fourth section end */}



            {/* Fifth section start */}

            <section>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <img className="w-100" src={Techfive} alt="Image" />
                        </div>
                        <div className="col-lg-6" >
                            <h3 className="tech-titile-two">Data Analytics and Credit scoring platform</h3>
                            <p className="tech-para-two pt-3">To measure qualitative and quantitative risk factors before financing individuals. Over the years Bee has collected data about different profiles of drivers, their payment behavior , and driving habits. With this we have come up with our inhouse scoring system to decide who is our best profile for giving assets and also extend further to Micro and Nano Loan . </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fifth section end */}



            <Footer />
        </>
    )
}

export default Tech;