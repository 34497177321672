import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import vid from "../../assests/images/video.mp4";
import visoin from "../../assests/images/vision.webp";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
// import bike from "../../assests/images/piggy-bank.png";
import bike from "../../assests/images/Bike_Financing.webp"
import Fuel from "../../assests/images/Fuel_Loan.webp";
import smart from "../../assests/images/Smartphone_Loan.webp";
import client1 from "../../assests/images/client1.png";
import client2 from "../../assests/images/google-black-founders-fund.png";
import client3 from "../../assests/images/catalytic_africa.png";
import client4 from "../../assests/images/CAN_logo.png";
import blog from "../../assests/images/blog-img.webp";
import app from "../../assests/images/app.webp";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../Api/Api";
import { useEffect } from "react";
import axios from "axios";
import Loan from "../../Components/Loan/Loan";
import b2b from "../../assests/images/B2b copy.png";
import gps_logo from "../../assests/images/GPS copy.png";


const Home = () => {

  const [loanModalOpen1, setLoanModalOpen1] = useState(false);
  const [loanModalOpen2, setLoanModalOpen2] = useState(false);
  const [loanModalOpen3, setLoanModalOpen3] = useState(false);
  const [key1, setKey1] = useState(0);
  const [key2, setKey2] = useState(0);
  const [key3, setKey3] = useState(0);

  const [pop, setPop] = useState("");

  const submitForm1 = (value) => {
    setPop(value);
    setLoanModalOpen1(true);
    setKey1(key1 + 1);
  };

  const submitForm2 = (value) => {
    setPop(value);
    setLoanModalOpen2(true);
    setKey2(key2 + 1);
  };

  const submitForm3 = (value) => {
    setPop(value);
    setLoanModalOpen3(true);
    setKey3(key3 + 1);
  };

  const [newsroomCard, setnewsroomCard] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    axios
      .request(api("news/allNewsRoom", "GET"))
      .then(function (response) {
        setnewsroomCard(response.data.data.list);
        //  console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const logoResponsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 4 },
  };

  const items = [
    <div className="service p-4 m-3">
      <div className="icon">
        <img src={bike} className="service_financing_img" alt="Bike Financing" />
      </div>
      <div className="service-name mt-3">
        <h4 className="dark-color">Bike Financing</h4>
        <p>
          Tired of renting bike from private people. Sign up with Bee for a
          flexible payment plan and own your bike in 12 months.
        </p>
      </div>
      <button id="bottone1">
        <NavLink
          to="/FinService"
          id="bottone1"
          className="mt-4"
        >
          <strong>
            Read more <FontAwesomeIcon icon={faAngleRight} />{" "}
          </strong>
        </NavLink>
      </button>
    </div>,
    <div className="service p-4 m-3">
      <div className="icon">
        <img src={Fuel} className="service_financing_img" alt="Fuel Loan" />
      </div>
      <div className="service-name mt-3">
        <h4 className="dark-color">Fuel Loan</h4>
        <p>
          Stop running out of fuel on a ride, buy fuel on loan with an Fuel Card. Our cards work at our partner petrol stations,
          allowing you to keep moving when it matters most.
        </p>
      </div>
      <button id="bottone1">
        <NavLink
          to="/FinService"
          id="bottone1"
          className="mt-4"
        >
          <strong>
            Read more <FontAwesomeIcon icon={faAngleRight} />{" "}
          </strong>
        </NavLink>
      </button>
    </div>,
    <div className="service p-4 m-3">
      <div className="icon">
        <img src={smart} className="service_financing_img" alt="Smartphone  Loan" />
      </div>
      <div className="service-name mt-3">
        <h4 className="dark-color">Smartphone Loan</h4>
        <p>
          Join the Mobile generation with an Bee Smartphone loan. Bee offers the
          latest phone models that fits your budget.
        </p>
      </div>
      <button id="bottone1">
        <NavLink
          to="/FinService"
          id="bottone1"
          className="mt-4"
        >
          <strong>
            Read more <FontAwesomeIcon icon={faAngleRight} />{" "}
          </strong>
        </NavLink>
      </button>
    </div>,
    <div className="service p-4 m-3">
      <div className="icon">
        <img src={bike} className="service_financing_img" alt=" B2B Delivery" />
      </div>
      <div className="service-name mt-3">
        <h4 className="dark-color">B2B Delivery</h4>
        <p>
          Your trusted partner for reliable logistics and delivery services<br></br>
          <br></br>
        </p>
      </div>
      <button id="bottone1" >
        <NavLink
          to="/bee-reactjs-website/FleetService"
          id="bottone1"
          className="mt-4"
        >
          <strong>
            Read more <FontAwesomeIcon icon={faAngleRight} />{" "}
          </strong>
        </NavLink>
      </button>
    </div>,
    <div className="service p-4 m-3">
      <div className="icon">
        <img src={gps_logo} alt="GPS Devices" />
      </div>
      <div className="service-name mt-3">
        <h4 className="dark-color">GPS Devices</h4>
        <p>
          Consectetuer adipiscing elmodo ligula eget dolor ociis natoque sit
          amet le polo konsog.<br></br>
          <br></br>
        </p>
      </div>
      <button id="bottone1" className="mt-4">
        <NavLink
          to="/bee-reactjs-website/FleetService"
          id="bottone1"
          className="mt-4"
        >
          <strong>
            Read more <FontAwesomeIcon icon={faAngleRight} />{" "}
          </strong>
        </NavLink>
      </button>
    </div>,
    // <div className="service p-4 m-3">
    //   <div className="icon">
    //     <img src={smart} alt="GPS Devices" />
    //   </div>
    //   <div className="service-name mt-3">
    //     <h4 className="dark-color">GPS Devices</h4>
    //     <p>
    //       Consectetuer adipiscing elmodo ligula eget dolor ociis natoque sit
    //       amet le polo konsog.<br></br>
    //       <br></br>
    //     </p>
    //   </div>
    //   <button id="bottone1" className="mt-4">
    //     <NavLink
    //       to="/FleetService"
    //       id="bottone1"
    //       className="mt-4"
    //     >
    //       <strong>
    //         Read more <FontAwesomeIcon icon={faAngleRight} />{" "}
    //       </strong>
    //     </NavLink>
    //   </button>
    // </div>

  ];

  const logo = [
    <div className="client-logo">
      <img src={client1} alt="Our Investors" />
    </div>,
    <div className="client-logo">
      <img src={client2} alt="Our Investors" />
    </div>,
    <div className="client-logo">
      <img src={client3} alt="Our Investors" />
    </div>,
    <div className="client-logo">
      <img src={client4} alt="Our Investors" />
    </div>,
  ];
  return (
    <>
      <Header />
      <>
        {/* banner */}
        <section>
          <div className="container mt-5">
            <div className="row align-items-center">
              <div className="col-xs-12 col-md-6 col-lg-6">
                <div className="banner-text">
                  <h1>Democratize the financing in Africa</h1>
                  <p className="mt-3 mb-5">
                    Our vision is to democratize financial services in Africa,
                    starting with asset financing for commercial drivers (
                    phones, vehicles, other equipment and services ).
                  </p>
                  <NavLink
                    to="/about"
                    id="bottone1"
                    className="mt-4"
                  >
                    <strong>Learn more</strong>
                  </NavLink>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6">
                {/* <div style={{ opacity: isVideoLoaded ? 1 : 0 }}>
                  <ReactPlayer
                    url={vid}
                    playing={true}
                    controls={false}
                    loop={true}
                    muted={true}
                    playsinline={false}
                    width="100%"
                    onReady={onLoadedData}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </section>

        {/* vison */}
        <section>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xs-12 col-md-6 col-lg-6 mb-5">
                <div className="vision-image">
                  <img className="w-100" src={visoin} alt="vision" />
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <div className="vision-text">
                  <h3>Vision</h3>
                  <p className="mt-5">
                    After piloting Motorcycle financing, our vision is to
                    finance other asset and also offer Micro- and Nano loan in
                    less than 24 hours by using our In-house{" "}
                    <span className="text-black">
                      Alternative Credit Scoring System
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* service */}
        <section>
          <div className="container-fluid m-0 p-0">
            <div className="dark-background py-5">
              <div className="container">
                <div className="title-section mb-5">
                  <h3 className="common-title light-color">Our Services</h3>
                  <p className="mt-3 light-color text-center px-4">
                    We will match you with a loan program that meet your
                    financial need. In short term liquidity, by striving to make
                    funds available to them{" "}
                    <span className="fw-bold">
                      within 24 hours of application.
                    </span>{" "}
                  </p>
                </div>
                <AliceCarousel
                  mouseTracking
                  items={items}
                  responsive={responsive}
                  paddingLeft="10"
                  disableDotsControls="false"
                  infinite="true"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Application */}
        <section>
          <div className="container">
            <div className="row">
              <div className="title-section mb-5">
                <h3 className="common-title dark-color">
                  Fast & Easy Application Process
                </h3>
                <p className="mt-3 dark-color text-center px-4">
                  Suspendisse aliquet varius nunc atcibus lacus sit amet coed
                  portaeri sque mami luctus viveed
                </p>
              </div>
             

              <div className="col-xs-12 col-md-4 col-lg-3 mb-5">
                <a
                  onClick={() => submitForm1("Bike Financing")}
                  className="text-decoration-none text-dark"
                  id=""
                >
                  <div class="custom-card p-2 text-center position-relative">
                    <div className="step-number m-auto d-flex justify-content-center position-absolute mb-4">
                      <div className="circle d-flex">
                        <p className="m-0">1</p>
                      </div>
                    </div>
                    <div className="service-name mt-5">
                      <h4 className="dark-color">Apply Online or In-person</h4>
                      <p>
                        Visit one of our office in Douala or Yaounde.<br></br>
                        <br></br>
                      </p>
                    </div>
                  </div>
                </a>
                {<Loan key={key1} openProps={loanModalOpen1} pop={pop} />}
              </div>

              <div className="col-xs-12 col-md-4 col-lg-3 mb-5">
                <div class="custom-card p-2 text-center position-relative">
                  <div className="step-number m-auto d-flex justify-content-center position-absolute mb-4">
                    <div className="circle d-flex">
                      <p className="m-0">2</p>
                    </div>
                  </div>
                  <div className="service-name mt-5">
                    <h4 className="dark-color">Verify Your Application</h4>
                    <p>
                      An Bee credit officer will work with you to verify your
                      information and approve your application.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 col-lg-3 mb-5">
                <div class="custom-card p-2 text-center position-relative">
                  <div className="step-number m-auto d-flex justify-content-center position-absolute mb-4">
                    <div className="circle d-flex">
                      <p className="m-0">3</p>
                    </div>
                  </div>
                  <div className="service-name mt-5">
                    <h4 className="dark-color">Get Your Loan</h4>
                    <p>
                      Once approved your are invited to one of our offices or
                      disbursement to pick up your bike.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 col-lg-3 mb-5">
                <div class="custom-card p-2 text-center position-relative">
                  <div className="step-number m-auto d-flex justify-content-center position-absolute mb-4">
                    <div className="circle d-flex">
                      <p className="m-0">4</p>
                    </div>
                  </div>
                  <div className="service-name mt-5">
                    <h4 className="dark-color">Repay & Build Credit</h4>
                    <p>
                      As you pay back you will increase your Bee credit score
                      and can unlock other financial products.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our Investors */}
        <section>
          <div className="container">
            <div className="row">
              <div className="title-section mb-5">
                <h3 className="common-title dark-color">Our Investors</h3>
              </div>
              <AliceCarousel
                mouseTracking
                items={logo}
                responsive={logoResponsive}
                paddingLeft="10"
                disableDotsControls="false"
                infinite="true"
                autoPlay="true"
                disableButtonsControls="false"
                autoPlayStrategy="none"
                autoPlayInterval="2000"
                animationType="slide"
                touchMoveDefaultEvents="true"
              />
            </div>
          </div>
        </section>

        {/* News room */}

        <section>
          <div className="container-fluid m-0 p-0">
            <div className="dark-background py-5">
              <div className="container news-room">
                <div className="title-section mb-5">
                  <h3 className="common-title light-color">NEWS ROOMS</h3>
                  <p className="mt-3 light-color text-center px-4">
                    Our mission is to deliver reliable, latest news and
                    opinions.
                  </p>
                </div>

                <div className="row">
                  {newsroomCard &&
                    newsroomCard.slice(0, 3).map((item, index) => (
                      <div
                        key={index}
                        className="col-12 col-md-6 col-lg-4 mb-3"
                      >
                        <div class="card">
                          <div class="image">
                            <img
                              src={item.imageUrl}
                              className="w-100"
                              alt="blog"
                            />
                          </div>
                          <div class="content">
                            <Link
                              to={`/ViewNewsroom/${item._id}`}
                              className="text-decoration-none"
                              href="/service"
                            >
                              <span class="title">{item.title}</span>
                            </Link>
                            <div className="d-flex justify-content-initial details-section mt-1">
                              <p className="pe-2 mb-0">Augest 25, 2022</p>
                              <p className="ps-2 mb-0">
                                By <span>Admin</span>
                              </p>
                            </div>
                            <p class="desc mt-3">{item.shortDescription}</p>
                            <Link
                              to={`/ViewNewsroom/${item._id}`}
                              className="d-flex align-items-center newsroom_list_read_more"
                            >
                              Read More{" "}
                              <span className="ms-2 d-inline-flex align-items-center newsroom_list-content_span justify-content-center">
                                <FontAwesomeIcon icon={faAngleRight} />
                              </span>{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}

                  <div className="col-12 text-center">
                    <button id="bottone1" className="mt-4">
                      <NavLink
                        to="/Newsroom"
                        className="text-decoration-none text-light "
                      >
                        <strong>
                          VIEW ALL NEWS <FontAwesomeIcon icon={faAngleRight} />{" "}
                        </strong>
                      </NavLink>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Business */}
        <section>
          <div className="container mt-5">
            <div className="row align-items-center">
              <div className="col-xs-12 col-md-6 col-lg-6">
                <div className="title-section mb-5">
                  <h3 className="common-title dark-color text-start">
                    One Of the leaders in{" "}
                    <span className="theme-color">Business</span>{" "}
                  </h3>
                  <p className="mt-3 dark-color">
                    Suspendisse aliquet varius nunc atcibus lacus sit amet coed
                    portaeri sque mami luctus viveed ametus odio velit auctor
                    nam elit nulla eget sodales dui pulvinar dolor strategic
                    planning dolor sit sectetur morethe.
                  </p>
                </div>
              </div>
              {/* <div className="col-xs-12 col-md-6 col-lg-6">
                <div style={{ opacity: isVideoLoaded ? 1 : 0 }}>
                  <ReactPlayer
                    url={vid}
                    playing={true}
                    controls={false}
                    loop={true}
                    muted={true}
                    playsinline={false}
                    width="100%"
                    onReady={onLoadedData}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* App */}
        <section>
          <div className="container mt-5">
            <div className="row align-items-center">
              <div className="col-xs-12 col-md-6 col-lg-6">
                <div className="mb-5 text-center px-5">
                  <img src={app} alt="app" className="w-100" />
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <h3 className="common-title dark-color text-start">
                  Download Our Free <br></br>
                  <span className="theme-color">Mobile App</span>{" "}
                </h3>
                <div className="d-flex mt-3">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tt.bee.partner&hl=en&gl=US&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="me-3 market-btn google-btn"
                    role="button"
                  >
                    <span class="market-button-subtitle">Download on the</span>
                    <span class="market-button-title">Google Play</span>
                  </a>
                  {/* <a
                    href="https://www.kobinet.com.tr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="market-btn apple-btn"
                    role="button"
                  >
                    <span class="market-button-subtitle">Download on the</span>
                    <span class="market-button-title">App Store</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    </>
  );
};

export default Home;
