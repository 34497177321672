import React, { useEffect } from "react";
import beeLogo from "../../assests/images/bee1.jpg";
import humber from "../../assests/images/humber.png";
import "./Header.css";
import { NavLink } from "react-router-dom";
import $ from "jquery";
const Header = () => {
  useEffect(() => {
    document.body.style = null;
    $(".modal-backdrop").hide();
  }, []);

  return (
    <>
      <nav className="navbar navbar-light fixed-top shadow  light-bacground ">
        <div className="container">
          <span className="navbar-brand mb-0">
            <NavLink to="/">
              {" "}
              <img src={beeLogo} alt="Bee logo" className="logo-size" />
            </NavLink>
          </span>
          <div className="right-section">
            {/* <button className="btn" id="bottone1"><strong>Apply Now</strong></button> */}
            <button
              className="humber-menu mx-3"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <span className="">
                <img src={humber} alt="menu" className="menu-size"></img>
              </span>
            </button>
          </div>
        </div>
      </nav>

      <div
        className="modal custom-modal true"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title light-color" id="exampleModalLabel">
                MENU
              </h5>
              <button
                type="button"
                className="close_btn light-color"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="white"
                  className="bi bi-x-lg "
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </button>
            </div>
            <div className="modal-body px-0">
              <ul className="list-group list-group-flush dark-color">
                <li className="m-0">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? "list-group-item light-color active"
                        : "list-group-item light-color inactive"
                    }
                  >
                    {" "}
                    Home
                  </NavLink>
                </li>
                <li className="m-0">
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive
                        ? "list-group-item light-color active"
                        : "list-group-item light-color inactive"
                    }
                  >
                    {" "}
                    About Us
                  </NavLink>
                </li>
                <li className="m-0 ">
                  <div
                    class="custom_accordion accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div class="accordion-item bg-transparent">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button
                          class="accordion-button collapsed  light-color"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          Services
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <NavLink
                            to="/FinService"
                            className={({ isActive }) =>
                              isActive
                                ? "list-group-item light-color bg-transparent active"
                                : "list-group-item light-color bg-transparent inactive"
                            }
                          >
                            {" "}
                            Financing Service
                          </NavLink>
                          <NavLink
                            to="/DeliveryService"
                            className={({ isActive }) =>
                              isActive
                                ? "list-group-item light-color bg-transparent active"
                                : "list-group-item light-color bg-transparent inactive"
                            }
                          >
                            {" "}
                            Delivery Service
                          </NavLink>
                          <NavLink
                            to="/FleetService"
                            className={({ isActive }) =>
                              isActive
                                ? "list-group-item light-color bg-transparent active"
                                : "list-group-item light-color bg-transparent inactive"
                            }
                          >
                            {" "}
                            Fleet Service
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="m-0">
                  <NavLink
                    to="/Tech"
                    className={({ isActive }) =>
                      isActive
                        ? "list-group-item light-color active"
                        : "list-group-item light-color inactive"
                    }
                  >
                    {" "}
                    Tech Infrastructure
                  </NavLink>
                </li>
                {/* <li className="m-0">
                  <NavLink
                    to="/Career"
                    className={({ isActive }) =>
                      isActive
                        ? "list-group-item light-color active"
                        : "list-group-item light-color inactive"
                    }
                  >
                    {" "}
                    Career
                  </NavLink>
                </li> */}
                <li className="m-0">
                  <NavLink
                    to="/Newsroom"
                    className={({ isActive }) =>
                      isActive
                        ? "list-group-item light-color active"
                        : "list-group-item light-color inactive"
                    }
                  >
                    {" "}
                    Newsroom
                  </NavLink>
                </li>
                <li className="m-0">
                  <NavLink
                    to="/Contact"
                    className={({ isActive }) =>
                      isActive
                        ? "list-group-item light-color active"
                        : "list-group-item light-color inactive"
                    }
                  >
                    {" "}
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
            {/* <div className="modal-footer">
                <button
                  type="button"
                  id="bottone1"
                  className="btn btn-secondary dark-color"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
