import React from "react";
import Header from "../../Components/Header/Header";
import Footer from '../../Components/Footer/Footer'
import './About.css'
import '../Home/Home.css'
import about from '../../assests/images/About_us.webp'
import CountUp from 'react-countup';
import visoin from '../../assests/images/visoin.png'
import eye from '../../assests/images/eye.png'
import mission from '../../assests/images/mission.png'
import values from '../../assests/images/values.png'
import { Chrono } from "react-chrono";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import client1 from '../../assests/images/client1.png'
import client2 from "../../assests/images/google-black-founders-fund.png";
import client3 from "../../assests/images/catalytic_africa.png";
import client4 from "../../assests/images/CAN_logo.png";
import team from '../../assests/images/team.png'
import facebook_icon from '../../assests/images/facebook_icon.png'
import twitter_icon from '../../assests/images/twitter_icon.png'
import instagram_icon from '../../assests/images/instagram_icon.png'
import app from '../../assests/images/app.png'

const About = () => {
    
    const logoResponsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 4 },
    };

    const items = [
      {
        title: "2018- 2019",
        cardTitle: "",
        cardSubtitle: "",
        cardDetailedText: `Incorporated Bee SARL in Cameroon and signed a country deal with JUMIA for ecommerce delivery till stop of operation by jumia in cameroon.`,
      },
      {
        title: "2020",
        cardTitle: "",
        cardSubtitle: "",
        cardDetailedText: `Pandemic Covid struck and to facilitate the life of people we started to offer last mile delivery service. We partnered with 1000 SME merchants for delivery.`,
      },
      {
        title: "2021",
        cardTitle: "",
        cardSubtitle: "",
        cardDetailedText: `We figured out over the years our delivery partners don't own their vehicles and are not flexible to work because of the high daily repayment to the owner. To solve the issue we decided to offer a lease to own a model of bikes. Piloted our first 100 bikes in crowdfunding.`,
      },
      {
        title: "2022",
        cardTitle: "",
        cardSubtitle: "",
        cardDetailedText: ` Extended our asset financing business from bike to smartphone and also micro loan. Signed an Partnership with Yango to provide our fleet as their B2B delivery and ride hailing partner 
Got co invest fund from Catalytic-Africa(The African startup Matching fund) with Cameroon Angel Network  
                     Got selected by Google for Blackfounder fund accelerator program. 
`,
      },
      {
        title: "2023",
        cardTitle: "",
        cardSubtitle: "",
        cardDetailedText: `Signed a deal with Untapped an institutional lender focused on Africa to finance debt for our assets.`,
      },
    ];

      const logo = [
        <div className="client-logo">
            <img src={client1} alt="Our Investors"/>
        </div>,
        <div className="client-logo">
            <img src={client2} alt="Our Investors"/>
        </div>,
        <div className="client-logo">
            <img src={client3} alt="Our Investors"/>
        </div>,
        <div className="client-logo">
            <img src={client4} alt="Our Investors"/>
        </div>,    
    ]
    return (
      <>
        <Header />

        {/* About  */}
        <section className="pb-0">
          <div className="container mt-5">
            <div className="row  align-items-center">
              <div className="col-xs-12 col-md-6">
                <div className="about-title">
                  <h1>What is the problem we are solving</h1>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="about-title">
                  <h3>About Bee</h3>
                  <p className="mt-3">
                  More than 20 millions commercial drivers in Africa are longing for Asset Financing, and as well Maintenance Service, Insurance Service, Delivery & Ride Hailing Services, and other Financial & Social Services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-0">
          <div className="container">
            <div className="about-image">
              <img className="w-100" src={about} alt="about" />
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-3">
                <div className="count-card text-center">
                  <h4>
                    <CountUp delay={2} end={250} /> k+
                  </h4>
                  <p>Loans</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-3">
                <div className="count-card text-center">
                  <h4>
                    <CountUp delay={2} end={680} /> k+
                  </h4>
                  <p>Employees</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-3">
                <div className="count-card text-center">
                  <h4>
                    <CountUp delay={2} end={17} /> k+
                  </h4>
                  <p>Offers made</p>
                </div>
              </div>
              <div className="col-xs-12 col-md-3">
                <div className="count-card text-center">
                  <h4>
                    <CountUp delay={2} end={125} /> k+
                  </h4>
                  <p>Monthly visitors</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="special-space">
          <div className="container-fluid position-relative">
            <span className="placeholder-text position-absolute">Bee</span>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xs-12 col-md-6">
                  <div className="car-section mb-3">
                    <div className="about-card p-3">
                      <div className="d-flex">
                        <div className="icon-section pe-3">
                          <div className="icon-background">
                            <img
                              src={eye}
                              alt="eye"
                              className="px-3 py-4"
                            ></img>
                          </div>
                        </div>
                        <div className="content-section">
                          <h4>Our Vision</h4>
                          <p>
                            After piloting Motorcycle financing, our vision is
                            to finance other asset and also offer Micro- and
                            Nano loan in less than 24 hours by using our
                            In-house Alternative Credit Scoring System
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="car-section mb-3">
                    <div className="about-card p-3">
                      <div className="d-flex">
                        <div className="icon-section pe-3">
                          <div className="icon-background">
                            <img
                              src={mission}
                              alt="mission"
                              className="px-3 py-4"
                            ></img>
                          </div>
                        </div>
                        <div className="content-section">
                          <h4>Our Mission</h4>
                          <p>
                            After piloting Motorcycle financing, our vision is
                            to finance other asset and also offer Micro- and
                            Nano loan in less than 24 hours by using our
                            In-house Alternative Credit Scoring System
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="car-section mb-3">
                    <div className="about-card p-3">
                      <div className="d-flex">
                        <div className="icon-section pe-3">
                          <div className="icon-background">
                            <img
                              src={values}
                              alt="values"
                              className="px-3 py-4"
                            ></img>
                          </div>
                        </div>
                        <div className="content-section">
                          <h4>Our Values</h4>
                          <p>
                            After piloting Motorcycle financing, our vision is
                            to finance other asset and also offer Micro- and
                            Nano loan in less than 24 hours by using our
                            In-house Alternative Credit Scoring System
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="vision-image">
                    <img src={visoin} alt="visoin" className="w-100"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="about-title text-center mb-4">
                <h3>Our Milestones</h3>
              </div>
              <Chrono
                items={items}
                mode="VERTICAL_ALTERNATING"
                theme={{
                  primary: "#030303",
                  secondary: "#FED919",
                  cardBgColor: "#fff",
                  titleColor: "black",
                  titleColorActive: "#000",
                  cardSubtitle: "#646464",
                }}
                fontSizes={{
                  cardSubtitle: "14px",
                  cardText: "14px",
                  title: "30px",
                }}
                hideControls="false"
                slideShow="true"
                slideItemDuration={1500}
                slideShowType="slide_from_sides"
              />
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="about-title text-center mb-4">
                <h3>Our Partners</h3>
              </div>

              <AliceCarousel
                mouseTracking
                items={logo}
                responsive={logoResponsive}
                paddingLeft="10"
                disableDotsControls="false"
                infinite="false"
                autoPlay="true"
                disableButtonsControls="false"
                autoPlayStrategy="none"
                autoPlayInterval="2000"
                animationType="slide"
              />
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xs-12">
                <div className="about-title text-center mb-5">
                  <h3>Our Team</h3>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 mb-5">
                <div class="card p-3 h-100">
                  <img className="card-img" src={team} alt="team"></img>
                  <div className="card-title">
                    <h4>Patrick Timani</h4>
                    <p>CEO </p>
                  </div>
                  <div className="hr mb-4 "></div>
                  <div class="card-info">
                    <p class="text-body">
                      Patrick is an engineer, 2X founder. Spent 5 years in
                      consulting sector. Previously launched and exited a
                      micro-credit lending platform.
                    </p>
                  </div>
                  <div className="d-flex">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-3 social"
                      href="https://www.facebook.com/"
                    >
                      <img src={facebook_icon} alt="facebook"></img>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-3 social"
                      href="https://www.facebook.com/"
                    >
                      <img src={instagram_icon} alt="facebook"></img>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-3 social"
                      href="https://www.facebook.com/"
                    >
                      <img src={twitter_icon} alt="facebook"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 mb-5">
                <div class="card p-3 h-100">
                  <img className="card-img" src={team} alt="team"></img>
                  <div className="card-title">
                    <h4>Grace Nsota</h4>
                    <p>COO </p>
                  </div>
                  <div className="hr mb-4 "></div>
                  <div class="card-info">
                    <p class="text-body">
                      Grace has 13yrs+ managing Operation and Marketing teams
                      working for Thales DIS (Gemalto) and our competitor Gozem
                      as Country Manager in Togo
                    </p>
                  </div>
                  <div className="d-flex">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-3 social"
                      href="https://www.facebook.com/"
                    >
                      <img src={facebook_icon} alt="facebook"></img>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-3 social"
                      href="https://www.facebook.com/"
                    >
                      <img src={instagram_icon} alt="facebook"></img>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-3 social"
                      href="https://www.facebook.com/"
                    >
                      <img src={twitter_icon} alt="facebook"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 mb-5">
                <div class="card p-3 h-100">
                  <img className="card-img" src={team} alt="team"></img>
                  <div className="card-title">
                    <h4>Vinoth Sarun</h4>
                    <p>CTO </p>
                  </div>
                  <div className="hr mb-4 "></div>
                  <div class="card-info">
                    <p class="text-body">
                      Vinoth has 8yrs+ managing tech teams working for Porsche.
                      Currently heading a team of 5 engineers.
                    </p>
                  </div>
                  <div className="d-flex">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-3 social"
                      href="https://www.facebook.com/"
                    >
                      <img src={facebook_icon} alt="facebook"></img>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-3 social"
                      href="https://www.facebook.com/"
                    >
                      <img src={instagram_icon} alt="facebook"></img>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="me-3 social"
                      href="https://www.facebook.com/"
                    >
                      <img src={twitter_icon} alt="facebook"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* App */}
        <section>
          <div className="container mt-5">
            <div className="row align-items-center">
              <div className="col-xs-12 col-md-6 col-lg-6">
                <div className="mb-5 text-center px-5">
                  <img src={app} alt="app" className="w-100" />
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <h3 className="common-title dark-color text-start">
                  Download Our Free <br></br>
                  <span className="theme-color">Mobile App</span>{" "}
                </h3>
                <div className="d-flex mt-3">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.tt.bee.partner&hl=en&gl=US&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="me-3 market-btn google-btn"
                    role="button"
                  >
                    <span class="market-button-subtitle">Download on the</span>
                    <span class="market-button-title">Google Play</span>
                  </a>
                  {/* <a
                    href="https://www.kobinet.com.tr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="market-btn apple-btn"
                    role="button"
                  >
                    <span class="market-button-subtitle">Download on the</span>
                    <span class="market-button-title">App Store</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
}

export default About;