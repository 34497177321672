import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../About/About.css";
import "./Contact.css";
import Banner from "../../assests/images/contact-banner.png";
import CallIcon from "../../assests/images/call-icon.png";
import ContactForm from "../../assests/images/contactform.png";
import loading from "../../assests/images/loading.gif";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { api } from "../../Api/Api";
import axios from "axios";

const Contact = () => {
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .nullable("")
      .matches(/^[a-zA-Z]+$/, "Name field can not be empty")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string()
      .trim() // Remove leading and trailing spaces
      .email("Invalid email") // Validate email format
      .required("Email is required") // Add required validation
      .test("notEmpty", "Email is required", (value) => {
        // Custom test function to check for non-empty value
        return value && value.trim().length > 0;
      }),
  });

  const [emailSent, setEmailSent] = useState(false);
  const [loader, serLoader] = useState(false);

  return (
    <>
      <Header />

      {/* Contact banner section start */}

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pt-5 pt-lg-0 pt-md-0">
              <h2 className="fs-40 fw-bold text-dark mb-4 text-lg-start text-md-start text-center">
                Contacts
              </h2>
              <p className="contact-para mb-5 text-lg-start text-md-start text-center">
                Readiness and collaborations for future pandemics, not just in
                neurological. Work with us, and you’ll quickly learn that Ativo
                Team has a true passion for quality programs and long-lasting.
              </p>
              <div className="d-flex align-items-center gap-3">
                <div className="call-icon">
                  <img className="w-35" src={CallIcon} alt="Image" />
                </div>
                <a className="call-banner-number" href="tel:+237650520150">
                  +(237) 650-520-150
                </a>
              </div>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 pt-md-0 ">
              <img className="w-100" src={Banner} alt="Image" />
            </div>
          </div>
        </div>
      </section>

      {/* Contact banner section end */}

      {/* map section start */}

      <section>
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15919.084547695184!2d9.773554983111582!3d4.067002267340548!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10610d9ae1171139%3A0x67e2255aa6af8938!2sBee%20Sarl!5e0!3m2!1sen!2sin!4v1687153683802!5m2!1sen!2sin"
                className="w-100 h-425"
              ></iframe>
            </div>
            <div className="col-lg-6 pt-lg-0 pt-5">
              <div className="">
                <h2 className="fs-36 fw-bold text-dark">Contact Information</h2>
                <p className="pt-3">
                  Get to know us, including how you can contact us and how we
                  keep your information secure.
                </p>
              </div>

              <div className="d-flex justify-content-around pt-lg-5 pt-4 flex-lg-row flex-md-row flex-column">
                <div>
                  <p className="fs-20 text-dark fw-bold">Main Office:</p>
                  <p className="text-black">
                    Rue Castelnau, Face Immeuble Yolos Akwa,<br></br>
                    BP 15395, Douala, Cameroon
                  </p>
                  <a className="map-contact-link" href="tel:+237650520150">
                    +(237) 650-520-150
                  </a>
                </div>
                <div>
                  <p className="fs-20 text-dark fw-bold">Opening Hours:</p>
                  <p className="text-black">
                    Every day: 8 am–6 pm <br></br>
                    Sat – Sun: 8 am–6 pm
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* map section end */}

      {/* location section start */}
{/* 
      <section>
        <div className="container">
          <h2 className="fs-36 fw-bold text-black text-center mb-5">
            All Locations
          </h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-lg-0 mb-4">
              <div className="location-card">
                <div className="px-lg-5 px-2 pt-lg-4 pt-2">
                  <p className="fs-20 fw-bold text-center text-black">
                    100 Franklin Ave, Nutley, NJ 07110, United States
                  </p>
                  <p className="text-center text-black">
                    Mon – Fri: 8:30am – 6pm <br></br>
                    Sat: 10am – 2pm
                  </p>
                </div>

                <a className="location-contact d-block p-3 text-center" href="">
                  + (123) 1800-567-8990
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-lg-0 mb-4">
              <div className="location-card">
                <div className="px-lg-5 px-2 pt-lg-4 pt-2">
                  <p className="fs-20 fw-bold text-center text-black">
                    100 Franklin Ave, Nutley, NJ 07110, United States
                  </p>
                  <p className="text-center text-black">
                    Mon – Fri: 8:30am – 6pm <br></br>
                    Sat: 10am – 2pm
                  </p>
                </div>

                <a className="location-contact d-block p-3 text-center" href="">
                  + (123) 1800-567-8990
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="location-card">
                <div className="px-lg-5 px-2 pt-lg-4 pt-2">
                  <p className="fs-20 fw-bold text-center text-black">
                    100 Franklin Ave, Nutley, NJ 07110, United States
                  </p>
                  <p className="text-center text-black">
                    Mon – Fri: 8:30am – 6pm <br></br>
                    Sat: 10am – 2pm
                  </p>
                </div>

                <a className="location-contact d-block p-3 text-center" href="">
                  + (123) 1800-567-8990
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* location section end */}

      {/* contact form section start */}

      <section>
        <div className="container">
          <div className="contact-form-bg">
            <div className="row">
              <div className="col-lg-6 text-center">
                <img className="w-75 m-auto" src={ContactForm} alt="Image" />
              </div>
              <div className="col-lg-6 pt-lg-0 pt-md-0 pt-5">
                <p className=" inqury-tag">Send us an inquiry</p>
                <h3 className="fs-36 fw-bold text-black mb-4">Contact Us</h3>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    message: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values, { resetForm }) => {
                    // same shape as initial values
                    serLoader(true);

                    axios
                      .request(api("contactUs/create", "POST", values))
                      .then(function (response) {
                        setEmailSent(true);
                        resetForm();
                        serLoader(false);
                        setTimeout(() => {
                          setEmailSent(false);
                        }, 5000);
                      })
                      .catch(function (error) {
                        console.error(error);
                      });
                  }}
                >
                  {({ errors, touched }) => (
                    <Form className="contact-form">
                      <div className="row">
                        <div className="col-lg-6 mt-4">
                          <label for="fname">Name*</label> <br></br>
                          <br></br>
                          <Field
                            placeholder="Full Name"
                            name="name"
                            type="text"
                            className="form-control"
                          />
                          {errors.name && touched.name ? (
                            <div className="form_error pt-1">{errors.name}</div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 mt-4">
                          <label for="email">E-mail*</label>
                          <br></br>
                          <br></br>
                          <Field
                            placeholder="Email"
                            name="email"
                            type="email"
                            className="form-control"
                          />
                          {errors.email && touched.email ? (
                            <div className="form_error pt-1">
                              {errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="pt-4">
                        <label for="message">Message</label>
                        <br></br>
                        <br></br>
                        <Field
                          placeholder="Write the Message"
                          component="textarea"
                          rows="4"
                          name="message"
                          className="form-control"
                        />
                        {errors.message && touched.message ? (
                          <div className="form_error pt-1">
                            {errors.message}
                          </div>
                        ) : null}
                      </div>

                      {!loader && (
                        <button
                          className="contact-submit-btn mt-4"
                          type="submit"
                        >
                          Send Message
                        </button>
                      )}
                      {loader && (
                        <button
                          className="contact-submit-btn mt-4 d-flex align-items-center"
                          disabled
                        >
                          Loading<span class="btn-ring"></span>
                        </button>
                      )}
                      {emailSent && (
                        <div class="alert alert-success mt-2" role="alert">
                          Mail Sent Successfully. We will contact you shortly.
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact form section end */}

      <Footer />
    </>
  );
};

export default Contact;
