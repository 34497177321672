import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Loan.css";
import axios from "axios";

const Loan = (props) => {
  const [emailSent, setEmailSent] = useState(false);
  const [loader, setLoader] = useState(false);

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Name field can not be empty")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .test('valid-phone-number', 'Invalid phone number', value => {
        const cleaned = ('' + value).replace(/\D/g, '');
        return cleaned.length === 10;
      }),
    location: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Location field can not be empty")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    financing_service_type: Yup.number()
      .required("Financing service type is required")
  });

  const [open, setOpen] = useState(props.openProps);
  const [modalname, setModalName] = useState(props.pop);

  useEffect(() => {
    setOpen(props.openProps);
  }, [props.openProps]);

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className="form_modal">
      <Modal open={open} onClose={onCloseModal} center>
        <div className="row">
          <div className="col-12 text-center form_modal-title">
            <h2>{modalname}</h2>
            <p>Your email address will not be published. Required fields are marked *</p>
          </div>
        </div>

        <Formik
          initialValues={{
            name: "",
            phone_number: "247",
            location: "",
            financing_service_type: props.id || 0
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { resetForm }) => {
            setLoader(true);

            axios.post("https://node.technotackle.in/Finserve/apply", values)
              .then(response => {
                setEmailSent(true);
                resetForm();
                setLoader(false);
                setTimeout(() => {
                  setEmailSent(false);
                }, 5000);
              })
              .catch(error => {
                console.error(error);
                setLoader(false);
              });
          }}
        >
          {({ errors, touched }) => (
            <Form className="form_modal-card">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <label className="form-label">Your name *</label>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Full Name"
                      className="form-control"
                    />
                    {errors.name && touched.name ? (
                      <div className="form_error pt-1">{errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <label className="form-label">Your Number *</label>
                    <Field name="phone_number" placeholder="Phone Number">
                      {({ field, form }) => (
                        <div>
                          <PhoneInput
                            {...field}
                            country="cm"
                            disableDropdown
                            disableCountryCode
                            onChange={(value) =>
                              form.setFieldValue("phone_number", value)
                            }
                            containerClass="phone-input-container"
                          />
                        </div>
                      )}
                    </Field>
                    {errors.phone_number && touched.phone_number ? (
                      <div className="form_error pt-1">{errors.phone_number}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <label className="form-label">Your Location *</label>
                    <Field
                      placeholder="Location"
                      name="location"
                      type="text"
                      className="form-control"
                    />
                    {errors.location && touched.location ? (
                      <div className="form_error pt-1">{errors.location}</div>
                    ) : null}
                  </div>
                </div>

                {/* Hidden field for financing_service_type */}
                <Field
                  name="financing_service_type"
                  type="hidden"
                />

                <div className="col-12">
                  <div className="mb-3 text-center">
                    {!loader && (
                      <button className="btn apply_btn" type="submit">
                        Apply now
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {loader && (
                <button
                  className="contact-submit-btn mt-4 d-flex align-items-center"
                  disabled
                >
                  Loading<span className="btn-ring"></span>
                </button>
              )}
              {emailSent && (
                <div className="alert alert-success mt-2" role="alert">
                  Mail Sent Successfully. We will contact you shortly.
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Loan;
