import React from 'react';
import { useParams } from 'react-router-dom';
import blogData from './Blogs.json'; // Adjust the path according to your directory structure
import Header from '../../Components/Header/Header';

const BlogDetail = () => {
    const { id } = useParams();
    const [blog, setBlog] = React.useState(null);

    React.useEffect(() => {
        const blogPost = blogData.list.find(post => post._id === id);
        setBlog(blogPost);
    }, [id]);

    if (!blog) {
        return <div>Loading....</div>;
    }

    return (
        <>
            <Header />

            <section className="">
                <div className="container w-75 mx-auto">
                    <div className=" py-5">
                        <div className=''>
                            <h1>{blog.title}</h1>
                            <hr className='pb-0 mb-0' />
                            <div className='d-flex py-3'>
                                {blog.createdAt}
                            </div>
                            <img src={blog.imageUrl_inside} alt={blog.title} className='w-100' />
                            <div className='py-4' style={{fontSize:'20px'}} dangerouslySetInnerHTML={{ __html: blog.description }} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogDetail;
