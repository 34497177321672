import React from "react";
import { useState,useEffect } from "react";
import Logo from "../../assests/images/bee1.jpg";
import "./SplashScreen.css";
function SplashScreen() {
  return (
    <div className="zoom-in-zoom-out">
      <img src={Logo} />
    </div>
  );
}

export default SplashScreen;
