import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../About/About.css";
import "./Career.css";
import CareerImageOne from "../../assests/images/career-image-one.png";
import CareerImageTwo from "../../assests/images/career-image-two.png";
import CareerImageThree from "../../assests/images/career-image-three.png";
import CareerImageFour from "../../assests/images/career-image-four.png";
import Vacation from "../../assests/images/vacation.png";
import Money from "../../assests/images/money.png";
import Calender from "../../assests/images/calender.png";
import QualityImage from "../../assests/images/beecareer.png";
import { Link } from "react-router-dom";
import { api } from "../../Api/Api";
import { useEffect, useState } from "react";
import axios from "axios";

const Career = () => {
  const [positionList, setpositionList] = useState([]);

  useEffect(() => {
    getpositionList();
  }, []);
  const getpositionList = async () => {
    axios
      .request(api("career/allCareer", "GET"))
      .then(function (response) {
        setpositionList(response.data);
        console.log("hello", response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <>
      <Header />

      {/* banner section start */}

      <section>
        <div className="container">
          <div className="row justify-content-center pt-5">
            <div className="col-lg-8">
              <h1 className="career-banner-title text-center">
                Not just work - your life's best work
              </h1>
              <p className="text-center pt-4 career-banner-para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <a className="available-job" href="#jobsdetail">
                See Available Jobs
              </a>
            </div>
          </div>

          <div className="row pt-5">
            <div className="col-lg-3 col-md-3 col-6">
              <img className="w-100 " src={CareerImageOne} alt="Image" />
            </div>

            <div className="col-lg-3 col-md-3 col-6">
              <img
                className="w-100 mt-lg-5 mt-md-5"
                src={CareerImageTwo}
                alt="Image"
              />
            </div>
            <div className="col-lg-3 col-md-3 col-6">
              <img
                className="w-100  mt-lg-0 mt-md-0 mt-4"
                src={CareerImageThree}
                alt="Image"
              />
            </div>
            <div className="col-lg-3 col-md-3 col-6">
              <img
                className="w-100 mt-lg-5 mt-md-5 mt-4"
                src={CareerImageFour}
                alt="Image"
              />
            </div>
          </div>
        </div>
      </section>

      {/* banner section start */}

      {/* Benifits section start */}

      <section>
        <div className="container">
          <h2 className="text-center py-lg-5 py-md-5 benifit-title">
            Perks and Benefits
          </h2>
          <div className="row pt-4">
            <div className="col-lg-4 col-md-6 mt-lg-4 mt-4">
              <div className="benifit-section  m-auto d-flex align-items-center justify-content-center">
                <img className="w-30" src={Calender} alt="Image" />
              </div>
              <h4 className="text-center pt-4 fw-bold fs-24">
                Flexible schedule
              </h4>
              <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-4 mt-5 mt-md-4">
              <div className="benifit-section  m-auto d-flex align-items-center justify-content-center">
                <img className="w-30" src={Vacation} alt="Image" />
              </div>
              <h4 className="text-center pt-4 fw-bold fs-24">Paid Holidays</h4>
              <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-4 mt-5 mt-md-4">
              <div className="benifit-section  m-auto d-flex align-items-center justify-content-center">
                <img className="w-30" src={Money} alt="Image" />
              </div>
              <h4 className="text-center pt-4 fw-bold fs-24">
                Competitive Salary
              </h4>
              <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-4 mt-5 mt-md-4 ">
              <div className="benifit-section  m-auto d-flex align-items-center justify-content-center">
                <img className="w-30" src={Calender} alt="Image" />
              </div>
              <h4 className="text-center pt-4 fw-bold fs-24">
                Flexible schedule
              </h4>
              <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-4 mt-5 mt-md-4">
              <div className="benifit-section  m-auto d-flex align-items-center justify-content-center">
                <img className="w-30" src={Vacation} alt="Image" />
              </div>
              <h4 className="text-center pt-4 fw-bold fs-24">Paid Holidays</h4>
              <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-4 mt-5 mt-md-4">
              <div className="benifit-section  m-auto d-flex align-items-center justify-content-center">
                <img className="w-30" src={Money} alt="Image" />
              </div>
              <h4 className="text-center pt-4 fw-bold fs-24">
                Competitive Salary
              </h4>
              <p className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Benifits section end */}

      {/* Qualities section start */}

      <section className="qualities-section">
        <div className="container">
          <div className="text-center">
            <h2 className="text-black fw-bold fs-40">The Bee Person</h2>
            <p className="qualities-para">
              The ideal Beeian exhibits these 8 qualities
            </p>
          </div>
          <div className="text-center qualities-image">
            <img
              className="w-30 image-career-v"
              src={QualityImage}
              alt="Image"
            />
            <ul className="position-absolute bee-quality-one">
              <li className="fw-bold">
                <span className="numbers d-flex justify-content-center align-items-center">
                  01
                </span>{" "}
                Internal drive
              </li>
              <li className="fw-bold">
                <span className="numbers d-flex justify-content-center align-items-center">
                  02
                </span>{" "}
                Empathy
              </li>
              <li className="fw-bold">
                <span className="numbers d-flex justify-content-center align-items-center">
                  03
                </span>{" "}
                Humility
              </li>
              <li className="fw-bold">
                <span className="numbers d-flex justify-content-center align-items-center">
                  04
                </span>{" "}
                Initiative
              </li>
            </ul>
            <ul className="position-absolute bee-quality-two">
              <li className="fw-bold">
                <span className="numbers d-flex justify-content-center align-items-center">
                  05
                </span>{" "}
                Bias for technology
              </li>
              <li className="fw-bold">
                <span className="numbers d-flex justify-content-center align-items-center">
                  06
                </span>{" "}
                Diligence
              </li>
              <li className="fw-bold">
                <span className="numbers d-flex justify-content-center align-items-center">
                  07
                </span>{" "}
                Candou
              </li>
              <li className="fw-bold">
                <span className="numbers d-flex justify-content-center align-items-center">
                  08
                </span>{" "}
                Collaboration
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Qualities section end */}

      {/* jobs section start */}

      <section id="jobsdetail">
        <div className="container">
          <div className="pb-5">
            <h4 className="job-title pb-2">Now it's your turn.</h4>
            <p className="job-para">
              We love making new friends. See a position you can fill? Tell us
              about yourself, we’ll take it from there if we have an opening.
            </p>
          </div>
          {positionList.data &&
            positionList.data.map((item, index) => (
              <div className="jobs-list-one">
                <h6 className="project-title fw-bold mb-5">
                  {item.categoryName}
                </h6>
                {item.positionList.map((i, index) => (
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2 job-contents mb-4"
                    key={i.jobId}
                  >
                    <p className="project-position mb-0">{i.jobName}</p>
                    <p className="text-dark fw-bold mb-0">{i.place}</p>
                    <Link
                      className="learn-more fw-bold"
                      to={`/CareerView/${i._id}`}
                    >
                      Learn More
                    </Link>
                  </div>
                ))}
              </div>
            ))}

          {/* <div className="jobs-list-two pt-5">
            <h6 className="project-title fw-bold mb-5">Design</h6>
            <div className="d-flex justify-content-between align-items-center px-3 py-2 job-contents mb-4">
              <p className="project-position mb-0">Sr. Product Manager</p>
              <p className="text-dark fw-bold mb-0">New York</p>
              <Link className="learn-more fw-bold" to="/CareerView">
                Learn More
              </Link>
            </div>
            <div className="d-flex justify-content-between align-items-center px-3 py-2 job-contents mb-4">
              <p className="project-position mb-0">Product Manager</p>
              <p className="text-dark fw-bold mb-0">Los Angeles</p>
              <Link className="learn-more fw-bold" to="/CareerView">
                Learn More
              </Link>
            </div>
            <div className="d-flex justify-content-between align-items-center px-3 py-2 job-contents">
              <p className="project-position mb-0">Mobile Product Strategist</p>
              <p className="text-dark fw-bold mb-0">New York</p>
              <Link className="learn-more fw-bold" to="/CareerView">
                Learn More
              </Link>
            </div>
          </div> */}
        </div>
      </section>

      {/* jobs section end */}

      <Footer />
    </>
  );
};

export default Career;
