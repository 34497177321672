import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import '../About/About.css'
import './Service.css'
import Background from '../../assests/images/breadcrumb.png'
import problem from '../../assests/images/problem.png'
import bee from '../../assests/images/bee.png'
import points from '../../assests/images/points.png'
import mobile from '../../assests/images/mobile.png'





const DeliveryService = () => {

    const [message, setMessage] = useState("");
    const [opne, setopne] = useState(false);

    const submitForm = () => {
        setMessage('Bike Financing');
        setopne(true);
    }




    return (
        <>
            <Header />
            <section className="p-0 m-0">
                <div className="pq-breadcrumb" style={{ background: `url(${Background})`, }}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <nav aria-label="breadcrumb">
                                    <div className="pq-breadcrumb-title">
                                        <h1>Our Services</h1>
                                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. .</p> */}
                                    </div>
                                    {/* <div className="pq-breadcrumb-container">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item">
                                                        <a href="index.html">
                                                            <i className="fas fa-home me-2"></i>Home
                                                        </a>
                                                    </li> 
                                                    <li className="breadcrumb-item active">About Us</li>			               			
                                                </ol>
                                        </div> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-0">
                <div className="container mt-5">
                    <div className="row  align-items-center">
                        <div className="col-xs-12 col-md-12">
                            <div className="about-title text-center mb-5">
                                <h1 className="text-uppercase">Delivery</h1>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="service-content">
                                <h3 className="mb-4">Problem</h3>
                                <p>E-commerce and Social Seller looking for professional delivery service to serve their customers.</p>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="image-part text-center">
                                <img className="" src={problem} alt="problem" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-0">
                <div className="container mt-5">
                    <div className="row  align-items-center">
                        <div className="col-xs-12 col-md-6">
                            <div className="image-part text-center mb-4">
                                <img className="" src={bee} alt="problem" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="service-content">
                                <h3 className="mb-4">Solution</h3>
                                <p>After financing and train our drivers we assign them to Merchant or company to make their delivery. Our pricing is align with they budget of the customer</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-0">
                <div className="container mt-5">
                    <div className="row  align-items-center">
                        <div className="col-xs-12 col-md-6">
                            <div className="service-content">
                                <h3 className="mb-4">Advantage</h3>
                                <div className="menu-use-full-link-container">
                                    <ul className=" ">
                                        <li className="menu-item mb-3"><img className="me-1" src={points} alt="points" /> We collect cash for the merchant</li>
                                        <li className="menu-item mb-3"><img className="me-1" src={points} alt="points" /> We give Business Analytic to and we finance stock for E-commerce</li>

                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="image-part text-center">
                                <img className="" src={problem} alt="problem" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="container">
                    <div className="row">

                        <div className="col-12">
                            <div className="service-card p-lg-5 p-xs-5 p-3 h-100">
                                <h4 className="mb-4">Become a Partner</h4>
                                <div className="d-flex align-items-center gap-3 flex-lg-row justify-content-lg-start justify-content-center flex-column">
                                    <div className="text-center mb-3">
                                        <img src={mobile} alt="bike" />
                                    </div>
                                    <p>After piloting Motorcycle financing, our vision is to finance other asset and also offer Micro- and Nano loan in less than 24 hours by using our In-house Alternative Credit Scoring System</p>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

            <Footer />


        </>
    )
}

export default DeliveryService;